<template>
  <v-autocomplete
    v-model="selectedLocal"
    :disabled="isUpdating"
    :items="items"
    :label="$t(label)"
    :rules="rules"
    :loading="loading"
    :item-text="itemText"
    :item-value="itemValue"
    :filter="filter"
    filled
    chips
    outlined
    multiple
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="removeItem(data.item)"
        color="primary"
      >
        <v-avatar left class="avatar">
          <v-img v-if="data.item.avatar_url" :src="data.item.avatar_url"></v-img>

          <v-icon color="secondary" v-else-if="data.item.isGroup" small
          >mdi-account-multiple</v-icon>

          <span v-else class="secondary--text text-uppercase font-weight-medium">{{ data.item.name[0] }}</span>
        </v-avatar>

        {{ data.item.name }}
      </v-chip>
    </template>

    <template v-slot:item="data">
      <template>
        <v-list-item-avatar class="list-avatar" :class="data.item.isGroup ? 'group-bg' : 'avatar'">
          <img
            v-if="data.item.avatar_url"
            :src="data.item.avatar_url"
          >

          <v-icon color="secondary" v-else-if="data.item.isGroup"
          >mdi-account-multiple</v-icon>

          <span v-else class="secondary--text text-uppercase font-weight-medium">{{ data.item.name[0] }}</span>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
          <v-list-item-subtitle v-if="data.item.email" v-html="data.item.email"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<style scoped>
  .avatar {
    background-color: var(--v-primary-base) !important;
  }

  .group-bg {
    background-color: var(--v-primary-base) !important;
  }

  .list-avatar {
    justify-content: center;
  }
</style>

<script>

export default {
  name: 'UserMultiSelect',

  props: {
    label: {
      type: String,
      default: "users"
    },

    items: {
      type: Array
    },

    selected: {
      type: Array
    },

    rules: {
      type: Array
    },

    loading: {
      type: Boolean
    },

    itemText: {
      type: String,
      default: "name"
    },

    itemValue: {
      type: String,
      default: "email"
    },
  },

  model: {
    prop: 'selected',
    event: 'selected-change'
  },

  data() {
    return {
      isUpdating: false,
    }
  },

  computed: {
    selectedLocal: {
      get() {
        return this.selected
      },

      set(value) {
        this.$emit('selected-change', value)
      }
    }
  },

  methods: {
    removeItem (item) {
      const index = this.selectedLocal.indexOf(item[this.itemValue])

      if (index >= 0) this.selectedLocal.splice(index, 1)
    },

    filter (item, queryText) {
      const name = item.name.toLowerCase()
      const email = item.email ? item.email.toLowerCase() : ''
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1 || (email && email.indexOf(searchText) > -1)
    }
  }
}
</script>